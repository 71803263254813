import React from 'react'
import { Link } from 'react-router-dom'
import ContactModal from '../../Modals/ContactModal'
import PropTypes from 'prop-types'
import '../Header/Header.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'

function LoginRegistrationHeader (props) {
  return (
        <div className='main-static-header'>

            <div className='static-header'>
                <div>
                    <Link to="/"><LazyLoadImage effect="blur" src="../image/QLlogo.svg" className='qls-img' alt='QLsupply' /></Link>
                </div>
                <div className='already-account'>
                    {
                        props.type === 'login'
                          ? <div>
                                <ContactModal />
                                <span className='new-qls'>Existing User? <Link to='/login' className='sign-link' > Login </Link></span>
                            </div>
                            // <span>Already have an account? <Link to="/login" className='sign-link'>Sign In</Link></span>
                          : <span className='new-qls'>New to QLS ? <Link to='/register' className='header-register' > Register</Link></span>
                    }
                </div>
            </div>
        </div>
  )
}

LoginRegistrationHeader.propTypes = {
  type: PropTypes.string
}

export default React.memo(LoginRegistrationHeader)
