import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { MdClose } from 'react-icons/md'
import './ContactModal.scss'
import NumberFormat from 'react-number-format'
import { LazyLoadImage } from 'react-lazy-load-image-component'

function ContactModal () {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <React.Fragment>
      <span className="new-qls register-header">
        Need Help?{' '}
        <a className="sign-link cursor-p" onClick={handleShow}>
          {' '}
          Contact Us{' '}
        </a>
      </span>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="contact-pop">
          <div className="pop-up-main">
            <a onClick={handleClose}>
              <MdClose className="close-img" />
            </a>
            <div className="contact-flex">
              <div className="contact-us">Contact Us</div>
              <div className="email--box">
                <LazyLoadImage effect="blur" src="./image/email.png" className="email--img" />
                <div className="email--div">
                  <div className="emai--text">Email us on </div>
                  <a href="mailto: support@qlsupply.com">
                    <div className="support">support@qlsupply.com</div>
                  </a>
                </div>
              </div>
              <div className="email--box">
                <LazyLoadImage effect="blur" src="./image/call.png" className="email--img" />
                <div className="email--div">
                  <div className="emai--text">Call us on </div>
                  <a href="tel:+18775647571">
                    <div className="support"><NumberFormat value={8775647571} displayType={'text'} format="+1 (###) ###-####"/></div>
                  </a>
                </div>
              </div>
              {/* <div className="email--box">
                <LazyLoadImage effect="blur" src="./image/email.png" className="email--img" />
                <div className="email--div">
                  <div className="support">Chat with us</div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}
export default React.memo(ContactModal)
