import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const ProtectRoutes = ({ component: Component, staffRoute, allowed }) => {
  return localStorage.getItem('token') !== null
    ? (
    <>
      {
        staffRoute
          ? <>
        {
          allowed ? <Component /> : <Redirect to="/login"></Redirect>
        }
        </>
          : <Component />
      }
    </>
      )
    : (
    <Redirect to="/login"></Redirect>
      )
}

ProtectRoutes.propTypes = {
  component: PropTypes.any,
  openAccount: PropTypes.bool,
  staffRoute: PropTypes.bool,
  allowed: PropTypes.bool
}

export default ProtectRoutes
