import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'

const PageNotFound404 = () => {
  return (
        <React.Fragment>
            <div className="site__body">
                <div className="block">
                    <div className="container">
                        <div className="not-found d-flex justify-content-center flex-row">
                            <div className="not-found__404">
                                <LazyLoadImage effect="blur" src="/image/page-not-found.png" alt="404" />
                            </div>
                            <div className="not-found__content">
                                <h1 className="not-found__title">Oops!</h1>
                                <p className="not-found__text">
                                    SORRY WE CAN&prime;T FIND THAT PAGE
                                </p>

                                <p className="not-found__sub-text">
                                    Either something went wrong, or the page doesn&prime;t exist.
                                </p>

                                <Link to='/'>
                                    <button className="go_to_home_btn">
                                        Go To Home
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
  )
}

export default React.memo(PageNotFound404)
