import { FETCH_CART, SET_CART, ERROR_CART, UPDATE_CART, UPDATE_CART_DATA, QUANTITY_LOADING, CLEAR_CART } from '../actions/types'

const initialState = {
  loading: false,
  quantityLoading: false,
  error: false,
  data: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CART:
      return {
        ...state,
        error: false,
        loading: true
      }

    case SET_CART:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      }

    case ERROR_CART:
      return {
        ...state,
        error: true,
        quantityLoading: false
      }

    case UPDATE_CART:
      return {
        ...state,
        loading: false,
        error: false
      }

    case QUANTITY_LOADING:
      return {
        ...state,
        quantityLoading: true
      }
    case UPDATE_CART_DATA:
    {
      const index = state.data.findIndex(x => x.part_id === action.payload.part_id)
      if (action.payload.quantity === 0) {
        if (index > -1) {
          state.data.splice(index, 1)
        }
      } else {
        if (index > -1) {
          state.data[index] = { ...state.data[index], quantity: action.payload.quantity, total: state.data[index].sell_price * action.payload.quantity }
        } else {
          state.data.push(action.payload)
        }
      }
      return {
        ...state,
        loading: false,
        quantityLoading: false
      } }

    case CLEAR_CART:
      return {
        ...state,
        data: [],
        loading: false,
        quantityLoading: false
      }

    default: {
      return state
    }
  }
}
