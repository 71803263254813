import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import './LoginRegistrationFooter.scss'

function LoginRegistrationFooter (props) {
  const currentYear = moment().year()
  return (
        <>
        <div className='main-footer'>
            <div className='privacy-security'>
                <div className='privacy-div'>
                <Link to='/page/privacy-policy' className='privacy-span' target="_blank">  Privacy & Security </Link>
                <Link to='/page/terms-and-conditions' className='privacy-span' target="_blank">Terms & Conditions</Link>
                </div>
            </div>
            <div className='copyright'>
            Copyright © {currentYear} QL SUPPLY. All rights reserved.
            </div>

        </div>
        </>
  )
}

export default React.memo(LoginRegistrationFooter)
