import React, { useEffect, useState, useContext } from 'react'
import store from '../../../redux/store'
import PropTypes from 'prop-types'
import { FooterContext, ScrollContext } from '../../../Routes/Routes'
import { useHistory } from 'react-router-dom'
import Header from '../Headers/Header/Header'
import Footer from '../Footers/Footer/Footer'
export default function WebsiteLayout (props) {
  const history = useHistory()
  const [userName, setUserName] = useState()
  const { footerState } = useContext(FooterContext)
  const { setScrollState } = useContext(ScrollContext)
  const state = store.getState()
  useEffect(() => {
    if (state.auth.isAuthenticated) {
      const user = state.auth.user
      const name = user.firstName
      setUserName(name)
    }
  }, [])

  useEffect(() => {
    setScrollState({ menuOne: false, menuTwo: false, menuThree: false })
  }, [history.location.pathname])
  return (
      <div className='d-flex flex-column justify-content-between' style={{ minHeight: '100vh' }}>
        <div>
        <Header name={userName} isOpenAccount={props.isOpenAccount} />
        {props.children}
        </div>
          {footerState && <Footer />}
      </div>
  )
}

WebsiteLayout.propTypes = {
  children: PropTypes.object,
  isOpenAccount: PropTypes.bool
}
