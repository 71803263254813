import { LOGIN_SUCCESS, LOGIN_FAILURE, VERIFICATION_SUCCESS } from '../actions/types'

const initialState = {
  user: localStorage.profile ? JSON.parse(localStorage.profile) : '',
  isAuthenticated: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isAuthenticated: false
      }
    case VERIFICATION_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      }

    default: {
      return state
    }
  }
}
