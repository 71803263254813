/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, memo } from 'react'
import { FaFacebookF, FaLinkedin, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import store from '../../../../redux/store'
import FooterSubscribe from '../FooterSubscribe/FooterSubscribe'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FooterContext } from '../../../../Routes/Routes'
import threadsIcon from '../../../../assets/images/threads.svg'
import './Footer.scss'
import moment from 'moment'

const Footer = () => {
  const state = store.getState()
  const [show, setShow] = useState(false)
  const [secondli, setSecondLi] = useState(false)
  const [thirdli, setThirdLi] = useState(false)
  const [fourli, setFourLi] = useState(false)
  const [name, setName] = useState('')
  const [siteData, setSiteData] = useState({})
  const { footerData } = useContext(FooterContext)
  const [linkData, setLinkData] = useState(null)

  const currentYear = moment().year()

  useEffect(() => {
    if (window.innerWidth > 767) {
      setShow(true)
      setSecondLi(true)
      setThirdLi(true)
      setFourLi(true)
    }
    if (localStorage.getItem('commonData') !== null) {
      setSiteData(JSON.parse(localStorage.getItem('commonData')).siteData)
      setLinkData(JSON.parse(localStorage.getItem('commonData')).footerData)
    }
  }, [localStorage.getItem('commonData')])

  useEffect(() => {
    if (state.auth.isAuthenticated) {
      const user = state.auth.user
      const name = user.firstName
      setName(name)
    }
  }, [state.auth.isAuthenticated, state.auth.user])

  // useEffect(() => {
  //    const x = document.getElementById("root");
  //    if(window.innerWidth < 778) {
  //        setShow(false);
  //    }
  // }, [])

  function handleScroll() {
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <div className="footer-top">
        {/* <div className="footer-subscribe-desktop">
          <div className="container">
            <div className="row">
              <FooterSubscribe />
            </div>
          </div>
        </div> */}
        <div className="footer-mobile-upper-view">
          <div style={{ display: 'flex' }} className="footer-main-logo">
            <Link
              to="/"
              className="footer-main-logo"
              style={{ display: 'flex' }}
            >
              <LazyLoadImage
                alt="QL Supply"
                effect="blur"
                width="auto"
                height="56.6px"
                src={siteData?.footerLogo}
                className="upper-footer-img" />
            </Link>
            <div className="footer__address">
              {/* Ave #20F Greenflower Ct, Bonita Springs, <br />
              New York, NY, 10016 <br /> */}
              Phone Number : <br />
              <span className="footer_phone__number">
                <a href="tel:+18775647571" className="text-white">
                  877.564.7571
                </a>
              </span>
              <p> Address: <br /> <span className='footer_phone__number'> 4770 Hickory Hill Road
                Memphis, TN 38141</span></p>
            </div>
            <div className="upper-social-img">
              <a
                href={siteData?.facebookLink}
                className="footer-social-icon"
              >
                <FaFacebookF className="text-white mx-2" fontSize={25} />
              </a>
              {/* <a
                href={siteData?.threadsLink}
                className="footer-social-icon"
              >
                <img className="mx-2" src={threadsIcon} width='20px' alt='threads icon'></img>
              </a> */}
              {/* <a
                href={siteData?.twitterLink}
                className="footer-social-icon"
              >
                <FaTwitter className="text-white mx-2" fontSize={20} />
              </a> */}
              <a
                href={siteData?.linkedinLink}
                className="footer-social-icon"
              >
                <FaLinkedin className="text-white mx-2" fontSize={25} />
              </a>
              <a
                href={siteData?.instagramLink}
                className="footer-social-icon"
              >
                <FaInstagram className="text-white mx-2" fontSize={25} />
              </a>
              {/* <a
                href={siteData?.youtubeLink}
                className="footer-social-icon"
              >
                <FaYoutube className="text-white mx-2" fontSize={20} />
              </a> */}
            </div>
          </div>
        </div>
      </div>

      {/* site__footer */}
      <footer className="site--footer">
        <div>
          <div className="main--footer">
            <div className="container">
              <div className="row main--li">
                <div className="col-md-12 col-lg-5">
                  <div className="footer-contactss">
                    <h5 className="product--categories-heading footer--desktop-media">
                      PRODUCT CATEGORIES{' '}
                    </h5>
                    <h5
                      className="product--categories-heading footer--mobile-media"
                      onClick={() => setShow(!show)}
                    >
                      PRODUCT CATEGORIES{' '}
                      <div
                        className="Plus-icon  float-end"
                        onClick={() => setShow(!show)}
                      >
                        {show ? '-' : '+'}{' '}
                      </div>
                    </h5>
                    {show && (
                      <div className="productt--lii">
                        <ul className="footer-links-list product-li oil-filter">
                          <Link to="/filters/oil-filter" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className=" allproducts hover-underline-animation">
                                Oil Filters
                              </span>
                            </li>
                          </Link>
                          <Link to="/filters/air-filter" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className="  allproducts hover-underline-animation">
                                Air Filters
                              </span>
                            </li>
                          </Link>
                          <Link to="/filters/cabin-air-filter" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className=" allproducts hover-underline-animation">
                                Cabin Air Filters
                              </span>
                            </li>
                          </Link>
                          <Link to="/filters/fuel-filter" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className=" allproducts hover-underline-animation">
                                Fuel Filters
                              </span>
                            </li>
                          </Link>
                          <Link to="/filters/transmission" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className=" allproducts hover-underline-animation">
                                Transmission Filters
                              </span>
                            </li>
                          </Link>
                        </ul>
                        <ul
                          className="footer-links-list product-li oil-filter"
                          id="product_li"
                        >
                          <Link to="/other-products/hose-clamps" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className=" allproducts hover-underline-animation">
                                Hose Clamps
                              </span>
                            </li>
                          </Link>
                          <Link to="/other-products/air-fresheners" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className="  allproducts hover-underline-animation">
                                Air Fresheners
                              </span>
                            </li>
                          </Link>
                          <Link to="/other-products/drain-plugs-and-gaskets" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className="  allproducts hover-underline-animation">
                                Drain Plugs and Gaskets
                              </span>
                            </li>
                          </Link>
                          <Link to="/other-products/oil-filter-housing-caps" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className="  allproducts hover-underline-animation">
                                Oil Filter Housing Caps
                              </span>
                            </li>
                          </Link>
                          <Link to="/ppe/gloves" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className=" allproducts hover-underline-animation">
                                Gloves
                              </span>
                            </li>
                          </Link>

                          {/* <Link to="/lighting" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className=" allproducts hover-underline-animation">
                                Lighting
                              </span>
                            </li>
                          </Link>
                          <Link to="/lubricants" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className=" allproducts hover-underline-animation">
                                Lubricants
                              </span>
                            </li>
                          </Link> */}
                        </ul>
                        <ul
                          className="footer-links-list product-li"
                          id="product_li"
                        >
                          {/* <Link to="/ppe" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className=" allproducts hover-underline-animation">
                                PPE
                              </span>
                            </li>
                          </Link>
                          <Link to="/shop-supplies" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className=" allproducts  hover-underline-animation">
                                Shop Supplies
                              </span>
                            </li>
                          </Link> */}
                          <Link to="/wiper-blades/wiper-blades" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className="  allproducts  hover-underline-animation">
                                Wiper Blade
                              </span>
                            </li>
                          </Link>
                          <Link to="/wiper-blades/rear-wiper-blade" onClick={handleScroll}>
                            <li className=" allproducts">
                              <span className=" allproducts  hover-underline-animation">
                                Rear Wiper Blades
                              </span>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12 col-lg-7">
                  <div className="row">
                    <div className="col-md-4 col-lg-4  pr-md-3">
                      <div className="footer-resource">
                        <h5 className="product--categories-heading footer--desktop-media">
                          RESOURCES{' '}
                        </h5>
                        <h5
                          className="product--categories-heading footer--mobile-media"
                          onClick={() => setSecondLi(!secondli)}
                        >
                          RESOURCES
                          <div
                            className="Plus-icon  float-end"
                            onClick={() => setSecondLi(!secondli)}
                          >
                            {secondli ? '-' : '+'}{' '}
                          </div>
                        </h5>
                        {secondli && (
                          <div className="productt--lii">
                            <ul className="footer-links-list">
                              <Link to="/about-us" onClick={handleScroll}>
                                <li className=" allproducts">
                                  <span className="  allproducts  hover-underline-animation">
                                    Why QLS
                                  </span>
                                </li>
                              </Link>
                              <Link to="/blogs" onClick={handleScroll}>
                                <li className=" allproducts">
                                  <span className="  allproducts  hover-underline-animation">
                                    Knowledge Database
                                  </span>
                                </li>
                              </Link>
                              <Link to="/faqs" onClick={handleScroll}>
                                <li className=" allproducts">
                                  <span className="  allproducts  hover-underline-animation">
                                    FAQs
                                  </span>
                                </li>
                              </Link>
                              <Link to="/industry-links" onClick={handleScroll}>
                                <li className=" allproducts">
                                  <span className="  allproducts  hover-underline-animation">
                                    Industry Links
                                  </span>
                                </li>
                              </Link>
                              <Link to={`/service-opportunities/${footerData?.slug}`} onClick={handleScroll}>
                                <li className=" allproducts">
                                  <span className="  allproducts  hover-underline-animation">
                                    Service Opportunities
                                  </span>
                                </li>
                              </Link>
                            </ul>
                            {/* <div className='vl'></div> */}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-5 col-lg-4  pr-md-3">
                      <div className="footer-account">
                        <h5 className="product--categories-heading footer--desktop-media">
                          MY ACCOUNT{' '}
                        </h5>
                        <h5
                          className="product--categories-heading footer--mobile-media"
                          onClick={() => setThirdLi(!thirdli)}
                        >
                          MY ACCOUNT
                          <div
                            className="Plus-icon  float-end"
                            onClick={() => setThirdLi(!thirdli)}
                          // style={{paddingRight:'15px'}}
                          >
                            {thirdli ? '-' : '+'}{' '}
                          </div>
                        </h5>
                        {thirdli && (
                          <div className="productt--lii ">
                            <ul
                              className="footer-links-list "
                              style={{ cursior: 'pointer' }}
                            >
                              {name !== ''
                                ? (
                                  <li className=" allproducts footer--log">
                                    <span className=" allproducts  hover-underline-animation footer--log">
                                      <Link
                                        to="/account-details"
                                        className=" allproducts footer--log"
                                        onClick={handleScroll}
                                      >
                                        <span className=" allproducts  hover-underline-animation">
                                          My Account
                                        </span>
                                      </Link>
                                    </span>
                                  </li>
                                )
                                : (
                                  <li className=" allproducts footer--log">
                                    <span className=" allproducts  hover-underline-animation footer--log">
                                      <Link
                                        to="/login"
                                        className=" allproducts footer--log"
                                        onClick={handleScroll}
                                      >
                                        {' '}
                                        Login{' '}
                                      </Link>{' '}
                                      /{' '}
                                      <Link
                                        to="/register"
                                        className=" allproducts footer--log"
                                        onClick={handleScroll}
                                      >
                                        {' '}
                                        Register{' '}
                                      </Link>
                                    </span>
                                  </li>
                                )}
                              {
                                name !== '' && <Link to="/" onClick={handleScroll}>
                                  <li className=" allproducts">
                                    <span className=" allproducts  hover-underline-animation">
                                      Order History
                                    </span>
                                  </li>
                                </Link>
                              }
                              {
                                name !== '' && <Link to="/preferred-products" onClick={handleScroll}>
                                  <li className=" allproducts">
                                    <span className=" allproducts  hover-underline-animation">
                                      Preferred Products
                                    </span>
                                  </li>
                                </Link>
                              }
                              <Link to="/contact-us" onClick={handleScroll}>
                                <li className=" allproducts">
                                  <span className=" allproducts  hover-underline-animation">
                                    Contact Us
                                  </span>
                                </li>
                              </Link>
                              <Link to="/all-products" onClick={handleScroll}>
                                <li className=" allproducts">
                                  <span className=" allproducts  hover-underline-animation">
                                    All Products
                                  </span>
                                </li>
                              </Link>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-4 pr-0 pr-md-3 footer-social-device">
                      <div className="footer-newsletter">
                        <h5 className="product--categories-heading">
                          POLICIES{' '}
                          <div
                            className="Plus-icon float-end"
                            onClick={() => setFourLi(!fourli)}
                          >
                            {fourli ? '-' : '+'}{' '}
                          </div>
                        </h5>
                        {fourli && (
                          <div className="productt--lii">
                            <ul className="footer-links-list social-md">
                              <Link
                                to="/page/privacy-policy"
                                onClick={handleScroll}
                              >
                                <li className=" allproducts">
                                  <span className=" allproducts  hover-underline-animation">
                                    Privacy Policy
                                  </span>
                                </li>
                              </Link>
                              <Link
                                to="/page/terms-and-conditions"
                                onClick={handleScroll}
                              >
                                <li className=" allproducts twitter-div">
                                  <span className=" allproducts  hover-underline-animation">
                                    Terms & Conditions
                                  </span>{' '}
                                </li>
                              </Link>
                              {/* <Link
                                to="/page/legal-disclaimer"
                                onClick={handleScroll}
                              >
                                <li className=" allproducts">
                                  <span className=" allproducts  hover-underline-animation">
                                    Legal Disclaimer
                                  </span>
                                </li>
                              </Link> */}
                              <Link
                                to="/page/shipping-and-returns"
                                onClick={handleScroll}
                              >
                                <li className=" allproducts youtube-div">
                                  <span className=" allproducts  hover-underline-animation">
                                    Shipping & Returns
                                  </span>
                                </li>
                              </Link>
                              {/* <Link
                                to="/page/cookie-policy"
                                onClick={handleScroll}
                              >
                                <li className=" allproducts youtube-div">
                                  <span className=" allproducts  hover-underline-animation">
                                    Cookie policy
                                  </span>
                                </li>
                              </Link> */}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row subscribe-footer-space">
                <div className="col-12">
                  <div className="footer-logo-row">
                    <div className="none">
                      <div
                        style={{ display: 'flex' }}
                        className="footer-main-logo footer-none-img align-items-end"
                      >
                        <Link style={{ alignSelf: 'flex-start' }} to="/" onClick={handleScroll}>
                          <LazyLoadImage
                            alt="QL Supply"
                            effect="blur"
                            width="auto"
                            height="56.6px"
                            src={siteData?.footerLogo}
                          />
                        </Link>
                        <div className="footer__address">
                          {/* Ave #20F Greenflower Ct, Bonita <br /> Springs, New
                          York, NY, 10016 <br /> */}
                          Phone Number : <br />
                          <span className="footer_phone__number">
                            <a href="tel:+18775647571" className="text-white">
                              877.564.7571
                            </a>
                          </span>
                          <p> Address: <br /> <span className='footer_phone__number'> 4770 Hickory Hill Road
                            Memphis, TN 38141</span></p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="footer-subscribe-device">
                      <FooterSubscribe />
                    </div> */}
                    {/* <div className="download-app-img">
                      <span className="download--app">DOWNLOAD THE APP</span>
                      <a href={linkData?.iosLink}>
                        <LazyLoadImage
                          effect="blur"
                          src="/image/ios-button (1).png"
                          alt="ios-btn"
                          className="ios--img-footer img1" />
                      </a>
                      <a href={linkData?.androidLink}>
                        <LazyLoadImage
                          effect="blur"
                          src="/image/android-button (1).png"
                          alt="android-btn"
                          className="ios--img-footer"
                        />
                      </a>
                    </div> */}
                    {/* <div className="copy--right">
                      Copyright © {currentYear} QLSUPPLY. All rights reserved.
                    </div> */}
                    <div className="lower-footer-social">
                      <a
                        href={siteData?.facebookLink}
                        className="footer-social-icon"
                      >
                        <FaFacebookF className="text-white mx-2" fontSize={25} />
                      </a>
                      {/* <a
                      href={siteData?.threadsLink}
                      className="footer-social-icon"
                    >
                      <img className="mx-2" src={threadsIcon} width='20px' alt='threads icon'></img>
                    </a> */}
                      {/* <a
                      href={siteData?.twitterLink}
                      className="footer-social-icon"
                    >
                      <FaTwitter className="text-white mx-2" fontSize={20} />
                    </a> */}
                      <a
                        href={siteData?.linkedinLink}
                        className="footer-social-icon"
                      >
                        <FaLinkedin className="text-white mx-2" fontSize={25} />
                      </a>
                      <a
                        href={siteData?.instagramLink}
                        className="footer-social-icon"
                      >
                        <FaInstagram className="text-white mx-2" fontSize={25} />
                      </a>
                      {/* <a
                      href={siteData?.youtubeLink}
                      className="footer-social-icon"
                    >
                      <FaYoutube className="text-white mx-2" fontSize={20} />
                    </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className=" footer--bottom--row ">
                <div className="copy--right">
                  Copyright © {currentYear} QLSUPPLY. All rights reserved.
                </div>
                {/* <div className="lower-footer-social">
                  <a
                    href={siteData?.facebookLink}
                    className="footer-social-icon"
                  >
                    <FaFacebookF className="text-white mx-2" fontSize={20} />
                  </a>
                  <a
                      href={siteData?.threadsLink}
                      className="footer-social-icon"
                    >
                      <img className="mx-2" src={threadsIcon} width='20px' alt='threads icon'></img>
                    </a>
                  <a
                      href={siteData?.twitterLink}
                      className="footer-social-icon"
                    >
                      <FaTwitter className="text-white mx-2" fontSize={20} />
                    </a>
                  <a
                    href={siteData?.linkedinLink}
                    className="footer-social-icon"
                  >
                    <FaLinkedin className="text-white mx-2" fontSize={20} />
                  </a>
                  <a
                    href={siteData?.instagramLink}
                    className="footer-social-icon"
                  >
                    <FaInstagram className="text-white mx-2" fontSize={20} />
                  </a>
                  <a
                      href={siteData?.youtubeLink}
                      className="footer-social-icon"
                    >
                      <FaYoutube className="text-white mx-2" fontSize={20} />
                    </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* site__footer / end */}
    </div>
  )
}

export default memo(Footer)
