import { createStore, combineReducers, applyMiddleware } from 'redux'
import authReducer from './reducers/authReducer'
import categoriesReducer from './reducers/categoriesReducer'
import cartReducer from './reducers/cartReducer'
import locationReducer from './reducers/locationReducer'
import thunk from 'redux-thunk'

const initialState = {}

const rootReducers = combineReducers({
  auth: authReducer,
  categories: categoriesReducer,
  cart: cartReducer,
  locations: locationReducer
})

const store = createStore(
  rootReducers,
  initialState,
  applyMiddleware(thunk)
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
