import React, { useEffect, useContext } from 'react'
import LoginRegistrationHeader from '../Headers/LoginRegistrationHeader/LoginRegistrationHeader'
import LoginRegistrationFooter from '../Footers/LoginRegistrationFooter/LoginRegistrationFooter'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { ScrollContext } from '../../../Routes/Routes'
export default function WebsiteLoginLayout (props) {
  const history = useHistory()
  const { setScrollState } = useContext(ScrollContext)

  useEffect(() => {
    setScrollState({ menuOne: false, menuTwo: false, menuThree: false })
  }, [history.location.pathname])
  return (
    <>
      <LoginRegistrationHeader type={props.headerLogin && 'login'}/>
        {props.children}
      <LoginRegistrationFooter />
    </>
  )
}

WebsiteLoginLayout.propTypes = {
  children: PropTypes.any,
  headerLogin: PropTypes.bool
}
