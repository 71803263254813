import { FETCH_CATEGORIES, SET_CATEGORIES, ERROR_CATEGORIES } from './types'
import { GetWithoutToken, GetData } from '../../helpers/apiHandler'
import toastr from 'toastr'

export const fetchCategories = (locationId) => async (dispatch) => {
  try {
    let apiUrl = 'customer/getCategories'
    if (locationId) {
      apiUrl += `?customerLocationId=${locationId}`
    }
    dispatch({ type: FETCH_CATEGORIES })
    const { result, status, message } = await GetData(apiUrl)
    if (status) {
      dispatch({ type: SET_CATEGORIES, payload: categoryOrder(result.data) })
    } else {
      dispatch({ type: ERROR_CATEGORIES })
      toastr.error(message, 'Error')
    }
  } catch (err) {
    dispatch({ type: ERROR_CATEGORIES })
    toastr.error('Error while fetching categories')
  }
}

export const fetchCustomerCategories = (locationId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CATEGORIES })
    const { result, status, message } = await GetData(`customer/getCategories?customerLocationId=${locationId}`)
    if (status) {
      dispatch({ type: SET_CATEGORIES, payload: result.data })
    } else {
      dispatch({ type: ERROR_CATEGORIES })
      toastr.error(message, 'Error')
    }
  } catch (err) {
    dispatch({ type: ERROR_CATEGORIES })
    toastr.error('Error while fetching categories')
  }
}

export const categoryOrder = (categories) => {
  // const order = [
  //   'Filters', 'Wiper Blades',
  //   'Lighting', 'Tire Service', 'Belts',
  //   'Brakes', 'Chemicals', 'Lubricants', 'PPE',
  //   'Shop Supplies', 'Miscellaneous'
  // ]
  const order = [
    'Filters', 'Wiper Blades',
    'Lighting', 'Tire Service', 'Belts',
    'Brakes', 'Chemicals', 'Lubricants', 'PPE',
    'Shop Supplies', 'Other Products'
  ]
  categories.sort((a, b) => {
    return order.indexOf(a.categoryName) - order.indexOf(b.categoryName)
  }
  )
  return categories
}
