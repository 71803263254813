import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Button = styled.button`
  background: ${(props) => (props.background ? props.background : '#645a5b')};
  padding: ${(props) => (props.padding ? props.padding : '10px 30px')};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : 'none'};
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  color: ${(props) => (props.color ? props.color : 'white')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0px'};
  width: ${(props) => (props.width === 'full' ? '100%' : 'fit-content')};
  font-family: ${(props) => (props.fontFamily && 'Futura-PT')};
  touch-events: ${(props) => (props.disabled === 'true' ? 'none' : '')}
  &:hover {
    background: ${(props) => (props.hoverColor ? props.hoverColor : '#645a5b')};
  }
  @media (max-width: 768px) {
    padding: ${(props) => (props.paddingMobile ? props.paddingMobile : '10px 20px')};
    letter-spacing: 0px;
    font-size: ${(props) =>
      props.mobilefontSize ? props.mobilefontSize + ' !important' : '14px !important'};
  }
`

export default function CustomButton (props) {
  return (
    <>
      <Button
        id={props.id ? props.id : 'custom-button'}
        onClick={props.handleClick}
        background={props.background}
        borderColor={props.borderColor}
        color={props.color}
        fontSize={props.fontSize}
        borderRadius={props.borderRadius}
        width={props.width}
        hoverColor={props.hoverColor}
        mobilefontSize={props.mobilefontSize}
        padding={props.padding}
        paddingMobile={props.paddingMobile}
        fontFamily={props.fontFamily}
        disabled={props.disableButton === 'true'}
      >
        {props.children}
      </Button>
    </>
  )
}

CustomButton.propTypes = {
  id: PropTypes.string,
  disableButton: PropTypes.string,
  handleClick: PropTypes.func,
  background: PropTypes.string,
  borderColor: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  borderRadius: PropTypes.string,
  width: PropTypes.string,
  hoverColor: PropTypes.string,
  mobilefontSize: PropTypes.string,
  padding: PropTypes.string,
  paddingMobile: PropTypes.string,
  fontFamily: PropTypes.bool,
  children: PropTypes.any
}
