import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const ProtectLoginRoutes = ({ component: Component }) => {
  return localStorage.getItem('token') !== null
    ? (
        <Redirect to="/"></Redirect>
      )
    : (
        <Component/>
      )
}

ProtectLoginRoutes.propTypes = {
  component: PropTypes.any
}

export default ProtectLoginRoutes
