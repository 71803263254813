import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

export default function BreadCrumb (props) {
  // function that capitalize the first letter of any string
  function capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return (
    <>
      <div className="common-breadcrumb container">
        <div className="d-flex align-items-center flex-wrap">
          <Link to="/">
            <span>Home</span>
          </Link>
          {props.slug?.split('/')?.map((slugData, i) => {
            return (
              <div key={i}>
                {slugData !== '' && (
                  <>
                    <span style={{ margin: '0 5px' }}>/</span>
                    {props.slug.split('/').length === i + 1
                      ? (
                      <>{slugData.split('-').map((innerSlug, i) => {
                        return <span className="mr-1 d-inline" key={i}>{capitalizeFirstLetter(innerSlug)}</span>
                      })}</>
                        )
                      : (props.slug.split('/')[i - 1] !== ''
                          ? props.notClickAble
                            ? <>{capitalizeFirstLetter(slugData)}</>
                            : <Link to={`/${props.slug.split('/')[i - 1]}/${slugData}`}>
                                <>{capitalizeFirstLetter(slugData)}</>
                            </Link>
                          : props.notClickAble
                            ? <>{capitalizeFirstLetter(slugData)}</>
                            : <Link to={`/${slugData}`}>
                                <>{capitalizeFirstLetter(slugData)}</>
                            </Link>
                        )}
                  </>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
BreadCrumb.propTypes = {
  slug: PropTypes.string.isRequired,
  notClickAble: PropTypes.bool
}
