import { FETCH_LOCATION, SET_LOCATION, ERROR_LOCATION, SELECT_LOCATION, REMOVE_LOCATION } from '../actions/types'

const initialState = {
  loading: true,
  error: false,
  loggedIn: false,
  data: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_LOCATION:
      return {
        ...state,
        error: false,
        loading: true,
        loggedIn: true
      }

    case SET_LOCATION:
      return {
        ...state,
        loading: false,
        error: false,
        loggedIn: true,
        data: action.payload
      }

    case ERROR_LOCATION:
      return {
        ...state,
        error: true,
        loggedIn: true
      }

    case SELECT_LOCATION:
      return {
        ...state,
        loggedIn: true,
        selectedLocation: action.payload
      }
    case REMOVE_LOCATION:
      return {
        loading: false,
        error: false,
        loggedIn: false,
        data: []
      }
    default: {
      return state
    }
  }
}
