import React from 'react'
import DashboardNav from './DashboardNav'
import PropTypes from 'prop-types'
import BreadCrumb from '../../../components/common/DynamicComponents/BreadCrumb'

const ManageAccountDashboard = (props) => {
  return (
    <React.Fragment>
      <div className="site">
            <div className="site__body">
                <BreadCrumb slug='My Account'/>
                 <div className="dashboard-container-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-3">
                                <DashboardNav openAccount={props.openAccount} />
                            </div>
                            <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                                { props.children }
                            </div>
                        </div>
                    </div>
                 </div>

                <div className="block-space block-space--layout--before-footer"></div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default React.memo(ManageAccountDashboard)

ManageAccountDashboard.propTypes = {
  openAccount: PropTypes.bool,
  children: PropTypes.any
}
