import { LOGIN_SUCCESS, LOGIN_FAILURE, VERIFICATION_SUCCESS } from './types'

export const getUser = (data) => {
  return {
    type: LOGIN_SUCCESS, payload: data
  }
}

export const deleteUser = () => {
  return {
    type: LOGIN_FAILURE
  }
}

export const verifyUser = () => {
  return {
    type: VERIFICATION_SUCCESS
  }
}
