import React from 'react'
import PropTypes from 'prop-types'

const Loader = (props) => {
  return (
    props.show === true
      ? <div className="loader-container">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
      : ''
  )
}

Loader.propTypes = {
  show: PropTypes.bool
}

export default React.memo(Loader)
