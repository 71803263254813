export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS'

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const ERROR_CATEGORIES = 'ERROR_CATEGORIES'

export const FETCH_CART = 'FETCH_CART'
export const SET_CART = 'SET_CART'
export const ERROR_CART = 'ERROR_CART'
export const UPDATE_CART = 'UPDATE_CART'
export const UPDATE_CART_DATA = 'UPDATE_CART_DATA'
export const QUANTITY_LOADING = 'QUANTITY_LOADING'
export const CLEAR_CART = 'CLEAR_CART'

export const FETCH_LOCATION = 'FETCH_LOCATION'
export const SET_LOCATION = 'SET_LOCATION'
export const ERROR_LOCATION = 'ERROR_LOCATION'
export const SELECT_LOCATION = 'SELECT_LOCATION'
export const REMOVE_LOCATION = 'REMOVE_LOCATION'
