import { FETCH_LOCATION, SET_LOCATION, ERROR_LOCATION, SELECT_LOCATION, REMOVE_LOCATION } from './types'
import { fetchCategories } from './categoriesAction'
import { GetData } from '../../helpers/apiHandler'
import toastr from 'toastr'

export const fetchLocations = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_LOCATION })
    const { result, status, message } = await GetData('customer/getLocations')
    if (status) {
      dispatch({ type: SET_LOCATION, payload: result })
      if (localStorage.getItem('selectedLocation') === '') {
        localStorage.setItem('selectedLocation', JSON.stringify(result[0]))
      } else if (JSON.parse(localStorage.getItem('selectedLocation')) === undefined || JSON.parse(localStorage.getItem('selectedLocation')) === null) {
        localStorage.setItem('selectedLocation', JSON.stringify(result[0]))
      } else {
        if (JSON.parse(localStorage.getItem('selectedLocation')).customerId !== result[0].customerId) {
          localStorage.setItem('selectedLocation', JSON.stringify(result[0]))
        }
      }
      dispatch(fetchCategories(JSON.parse(localStorage.getItem('selectedLocation'))?.id))
    } else {
      dispatch({ type: ERROR_LOCATION })
      toastr.error(message, 'Error')
    }
  } catch (err) {
    dispatch({ type: ERROR_LOCATION })
    toastr.error('Error while fetching locations')
  }
}

export const selectLocation = (location) => dispatch => {
  dispatch({ type: SELECT_LOCATION, payload: location })
}

export const removeLocations = () => dispatch => {
  dispatch({ type: REMOVE_LOCATION })
}
