import { FETCH_CATEGORIES, SET_CATEGORIES, ERROR_CATEGORIES } from '../actions/types'

const initialState = {
  loading: false,
  error: false,
  data: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return {
        ...state,
        error: false,
        loading: true
      }

    case SET_CATEGORIES:
      return {
        ...state,
        loading: false,
        error: false,
        data: [...action.payload]
      }

    case ERROR_CATEGORIES:
      return {
        ...state,
        error: true
      }

    default: {
      return state
    }
  }
}
